import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <p>I'm a Front End Developer in Wellington, New Zealand.</p>
        <p>You can contact me via email: <a href="mailto:hello@tomyoung.co.nz">hello@tomyoung.co.nz</a></p>
  </Layout>
)

export default IndexPage
